import Keycloak from 'keycloak-js';

let Vue;

class KeycloakPlugin extends Keycloak {

  constructor(...args) {
    super(...args);
    this.onAuthError = () => this.login();
    this.readyConnected = new Promise((resolve) => {
      this.onReady = (authenticated) => {
        if (authenticated) resolve();
        else this.login();
      };
    });
  }

  async getValidToken() {
    await this.readyConnected;

    for (let i = 0; i < 3; i++) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await this.updateToken(30);
        return this.token;
        // eslint-disable-next-line no-unused-vars
      } catch (e) {
        if (!this.token) return this.login();
      }
    }
    return this.login();
  }

  set timeSkew(val) {
    if (!this.refreshTokenParsed) return;

    this._timeSkew = val;
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = null;
    }
    const refreshExpiry = (this.refreshTokenParsed.exp * 1000)
      - Date.now()
      + (this._timeSkew * 1000)
      + 180000; // Add 3 min to allow session to expire in keycloak

    if (refreshExpiry <= 0) {
      setTimeout(() => this.onRefreshTokenExpired(), 0);
    } else {
      this.refreshTimeout = setTimeout(() => {
        if (this.onRefreshTokenExpired) {
          this.onRefreshTokenExpired();
        }
      }, refreshExpiry);
    }
  }

  get timeSkew() {
    return this._timeSkew;
  }

  // Fix for bug in keycloak while using native promises
  login() {
    const loginPromise = super.login();
    loginPromise.success = loginPromise.then;
    loginPromise.error = loginPromise.catch;
    return loginPromise;
  }

  hasSiteRole(siteCode, role) {
    const { resource_access: resourceAccess } = this.tokenParsed;
    const siteAccess = Object.entries(resourceAccess)
      .filter(([k]) => k.startsWith('site-'))
      .reduce((acc, [k, v]) => {
        acc[k.substring(5).toUpperCase()] = v;
        return acc;
      }, {});
    const site = siteAccess[siteCode.toUpperCase()];
    if (!site) return false;
    if (!site.roles.includes(role)) return false;
    return true;
  }

  // Vue specific code required to hide away 'installing' the broker functionality on the Vue prototype
  install(_Vue) {
    if (Vue && _Vue === Vue) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(
          '[broker] already installed. Vue.use(MessageBroker) should be called only once.'
        );
      }
      return;
    }
    Vue = _Vue;
    Vue.prototype.$kauth = this;
  }

}

export default KeycloakPlugin;
